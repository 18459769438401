import React from "react";
import { ReactTyped, Typed } from "react-typed";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                {/* <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1> */}
                {/* Ici, le composant Typed pour le slogan */}
                <ReactTyped
                  strings={[
                    "DATA FOR BETTER",
                    "DATA FOR IMPACT",
                    "DATA FOR SUCCESS",
                  ]}
                  typeSpeed={40}
                  backSpeed={50}
                  loop
                  style={{ color: 'white', fontSize: '7rem' }} // Applique directement les styles
                />


                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  En savoir plus
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
