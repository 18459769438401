import React from "react";
import logo from '../img/logo.jpeg';

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top" style={{ margin: 0, paddingTop: 0, paddingBottom:0 }}>
      <div className="container1"  style={{  padding: 0 }}>
        <div className="navbar-header" > 
          <button
            type="button"
            className="navbar-toggle collapsed hidden-xs"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {/* Import du logo */}
          <img className="logo-image" href="#page-top" src={logo} alt="logo" style={{ margin: 0, padding: 0 }} />

  
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                Secteurs
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                Qui sommes nous
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Nos Services
              </a>
            </li>
            {/* <li>
              <a href="#portfolio" className="page-scroll">
                Portfolio
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li> */}
            <li>
              <a href="#team" className="page-scroll">
                l'Équipe
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
